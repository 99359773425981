import { createSSRApp, createApp } from "vue";
import { createHead } from "@vueuse/head"
import App from "./App.vue";
import router from "../router";
import { state } from "./store";
const isServer = typeof window === "undefined";
const head = createHead()
const createAppInstance = (app) => {
  app.provide("globalState", state);
  app.use(head)
  app.use(router); 
  return app;
};

const app = isServer ? createSSRApp(App) : createApp(App);

createAppInstance(app);

if (!isServer) {
  app.mount("#app");
}